<template>
  <NuxtLoadingIndicator bg-primary-500 :color="false" />
  <main flex flex-col min-h-full>
    <NavigationBar />
    <div grow overflow-x-hidden relative>
      <slot />
    </div>
    <Footer />
    <FooterSecurity />
    <Cookies />
  </main>
</template>

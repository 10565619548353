<template>
  <div text-center sm:text-left>
    <h3 font-600>
      Bitbeli s.r.o.
    </h3>
    <h4>Jaurisova 515/4</h4>
    <h4>140 00 Praha 4 - Michle</h4>
    <h3 mt-24px>
      IČ: 01951254
    </h3>
  </div>
</template>
